import { action, makeObservable, observable, toJS } from "mobx";
import { Messages } from "../helpers/messages";
import MatchModel, { IMatch, IMatchResults } from "../models/Match";
import { toast } from "react-toastify";
import { getMessages } from "../helpers/getMessages";
import type { FirebaseError } from "firebase/app";
import { getCourseDetail, getCourses } from "../services/courses";
import type { GolfCourse } from "../services/courses";
import type { IPlayer } from "../models/Tournament";
import ScoreViewModel from "./ScoreViewModel";
import UserViewModel from "./UserViewModel";
import MatchViewModel from "./MatchViewModel";
import TournamentModel from "../models/Tournament";
import {
  getMatchesByTournamentId,
  getMatchesByTournamentIdAndRound,
  getPlayoffsMatchesByTournamentId,
} from "../services/firebase";

export interface IGolfCourse {
  course: GolfCourse;
  label?: string;
  teeBoxes?: Array<{
    id: string;
    color: string;
    length: number;
  }>;
  isOpen: boolean;
}

class PlayViewModel {
  match: MatchModel = new MatchModel();
  currentTournament: TournamentModel = new TournamentModel();
  courses: Array<IGolfCourse> = [];
  emailList: Array<Partial<IPlayer>> = [];
  currentTeeBox = "";
  currentTeeBoxDisplayName = "";
  author: UserViewModel = new UserViewModel();
  idMatch = "";
  tournamentId = "";
  currentDistance: Array<number> = [];
  currentHcp: Array<number> = [];
  currentPar: Array<number> = [];
  openModal = false;
  holeModal = "";
  parModal = "";
  modalKey = 0;
  currentStep = 0;
  allPlayers: Array<ScoreViewModel> = [];
  matches: Array<MatchViewModel> = [];
  emailListPlayedRound: Array<string> = [];

  constructor() {
    makeObservable(this, {
      match: observable,
      emailList: observable,
      currentTeeBox: observable,
      courses: observable,
      author: observable,
      idMatch: observable,
      tournamentId: observable,
      currentTeeBoxDisplayName: observable,
      currentDistance: observable,
      currentHcp: observable,
      currentPar: observable,
      currentStep: observable,
      openModal: observable,
      holeModal: observable,
      parModal: observable,
      modalKey: observable,
      allPlayers: observable,
      emailListPlayedRound: observable,
      getCourses: action,
      openCloseCourse: action,
      selectTeeBox: action,
      getAuthor: action,
      setAuthor: action,
      getEmailList: action,
      addEmailToList: action,
      removeEmailFromList: action,
      setModal: action,
      setModalValues: action,
      setScoreModal: action,
    });
    this.getCourses();
  }

  setCurrentStep(step: number): void {
    this.currentStep = step;
    if (
      this.currentStep === 2 &&
      this.currentTournament.tournamentType !== "dogfight"
    ) {
      const author = this.allPlayers[0];
      const players = [...this.allPlayers].slice(1);
      players.forEach((player) => {
        const newMatch = new MatchViewModel();
        newMatch.setAuthor(this.author);
        newMatch.setPlayers([author, player]);
        newMatch.tournamentId = this.tournamentId;
        newMatch.matchResults = [];
        // Set TeeBox and Course
        newMatch.setMatch(this.match);

        newMatch.currentDistance = this.currentDistance;
        newMatch.currentHcp = this.currentHcp;
        newMatch.currentPar = this.currentPar;
        newMatch.tournamentId = this.tournamentId;
        newMatch.currentTournament = this.currentTournament;

        newMatch.setDifferenceHP();
        this.matches.push(newMatch);
      });
    }
    if (
      this.currentStep === 2 &&
      this.currentTournament.tournamentType === "dogfight"
    ) {
      const author = this.allPlayers[0];
      const players = [...this.allPlayers].slice(1);
      const newMatch = new MatchViewModel();
      newMatch.setAuthor(this.author);
      newMatch.setPlayers([author, ...players]);
      newMatch.tournamentId = this.tournamentId;
      newMatch.matchResults = [];
      newMatch.setMatch(this.match);
      newMatch.currentDistance = this.currentDistance;
      newMatch.currentHcp = this.currentHcp;
      newMatch.currentPar = this.currentPar;
      newMatch.tournamentId = this.tournamentId;
      newMatch.currentTournament = this.currentTournament;
      newMatch.setDifferenceHPDogfight();
      this.matches.push(newMatch);
      console.log(toJS(this.currentTournament), "currentTournament");
    }
  }

  setModalValues(key: number): void {
    this.modalKey = key;
    this.holeModal = String(key + 1);
    this.parModal = String(this.currentPar[key]);
  }

  setModal(value: boolean): void {
    this.openModal = value;
  }

  setScoreModal(scores: Array<number>, hole: number): void {
    if (this.currentTournament.tournamentType !== "dogfight") {
      scores.forEach((score, key) => {
        this.allPlayers[key].setHoleScore(hole, score);
      });
      const author = scores[0];
      const allScores = [...scores].slice(1);
      const allScoresMatches = allScores.map((score) => [author, score]);
      this.matches.forEach((match, key) => {
        match.setHoleScores(allScoresMatches[key], hole);
        match.calculateWinners();
      });
      //this.setModal(false);
    }
    if (this.currentTournament.tournamentType === "dogfight") {
      scores.forEach((score, key) => {
        this.allPlayers[key].setHoleScore(hole, score);
      });
      const author = scores[0];
      const allScores = [...scores].slice(1);
      const allScoresMatches = allScores.map((score) => [author, allScores]);
      this.matches.forEach((match, key) => {
        match.setHoleScores(scores, hole);
        match.calculateWinnersDogfight();
      });
      //this.setModal(false);
    }
  }

  getAuthor(): string {
    return this.author.getUserId();
  }

  setAuthor(author: UserViewModel): void {
    this.author = author;
    const name = `${this.author.user.name} ${this.author.user.lastName}`;
    this.addEmailToList(this.author.user.email, name, 0);
  }

  getSelectedTeeBox() {
    return this.match.teeBox;
  }

  addEmailToList(email: string, name: string, handicap: number): void {
    const hp = parseInt(handicap.toString());
    this.emailList.push({ name, email, handicap: hp });
    const player = new ScoreViewModel();
    player.setPlayer(name, email, hp);
    this.allPlayers.push(player);
  }

  updateEmailList(email: string, name: string, handicap: number, key: number) {
    const hp = parseInt(handicap.toString());
    this.emailList[key] = { name, email, handicap: hp };
    this.allPlayers[key].setPlayer(name, email, hp);
  }

  removeEmailFromList(key: number): void {
    const newEmailList = [...this.emailList];
    const newAllPlayers = [...this.allPlayers];
    newEmailList.splice(key, 1);
    newAllPlayers.splice(key, 1);
    this.emailList = newEmailList;
    this.allPlayers = newAllPlayers;
  }

  getEmailList(): Array<Partial<IPlayer>> {
    return this.emailList;
  }

  selectTeeBox(course: GolfCourse, id: string) {
    this.match.course = course.id;
    this.match.courseDisplayName = course.name;
    const teeBoxKey = course.teeBoxes.findIndex((c) => c.id === id);
    if (teeBoxKey >= 0) {
      this.currentTeeBox = course.teeBoxes[teeBoxKey].id;
      this.currentTeeBoxDisplayName = course.teeBoxes[teeBoxKey].color;
      this.match.teeBox = course.teeBoxes[teeBoxKey].id;
      this.match.teeBoxDisplayName = course.teeBoxes[teeBoxKey].color;
      const teeBoxDetail = getCourseDetail(this.currentTeeBox);
      this.currentDistance = teeBoxDetail.distance;
      this.currentHcp = teeBoxDetail.hcp;
      this.currentPar = teeBoxDetail.par;
    }
  }

  closeAllCourses() {
    this.courses.forEach((element) => {
      element.isOpen = false;
    });
  }

  openCloseCourse(id: string) {
    this.closeAllCourses();
    const key = this.courses.findIndex(({ course }) => course.id === id);
    if (key >= 0) {
      this.courses[key].isOpen = !this.courses[key].isOpen;
    }
  }

  async getCourses(): Promise<void> {
    try {
      const tempCourses = await getCourses().courses;

      this.courses = tempCourses.map((course) => ({
        isOpen: false,
        label: course.name,
        course,
      }));
    } catch (error) {
      const displayLoading = getMessages(Messages.LOADING);
      const cuToast = toast.loading(displayLoading);
      const codeError = (error as FirebaseError).code;
      const displayError = getMessages(codeError);
      toast.update(cuToast, {
        render: displayError,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 800,
      });
    }
  }

  setMatchId(id: string): void {
    this.idMatch = id;
  }

  async createMatch(message: string, onFinish: () => void): Promise<void> {
    const idTournament = this.tournamentId;
    const matches = await getMatchesByTournamentId(idTournament);
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    for (const match of this.matches) {
      const player1 = match.players[0].score.idPlayer;
      const player2 = match.players[1].score.idPlayer;
      const name1 = match.players[0].score.player;
      const name2 = match.players[1].score.player;
      const checkIfMatchExist = async (
        player1: string,
        player2: string,
        matches: IMatch[]
      ): Promise<boolean> => {
        const exist = matches.some((match) => {
          if (
            match.matchResults[0].idPlayer === player1 &&
            match.matchResults[1].idPlayer === player2
          ) {
            return true;
          }
          if (
            match.matchResults[1].idPlayer === player1 &&
            match.matchResults[0].idPlayer === player2
          ) {
            return true;
          }
        });
        return exist;
      };
      const exist = await checkIfMatchExist(player1, player2, matches);
      const alreadyMessage = `Match ${name1} vs ${name2} was previously posted.`;
      if (!exist) {
        await match.createMatch(message);
      } else {
        toast.update(cuToast, {
          render: alreadyMessage,
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 7000,
        });
      }
    }
    toast.dismiss(cuToast);
    onFinish();
  }

  async createMatchPlayoffs(
    message: string,
    onFinish: () => void
  ): Promise<void> {
    const idTournament = this.tournamentId;
    const matches = await getPlayoffsMatchesByTournamentId(idTournament);
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    for (const match of this.matches) {
      const player1 = match.players[0].score.idPlayer;
      const player2 = match.players[1].score.idPlayer;
      const name1 = match.players[0].score.player;
      const name2 = match.players[1].score.player;
      const checkIfMatchExist = async (
        player1: string,
        player2: string,
        matches: IMatch[]
      ): Promise<boolean> => {
        const exist = matches.some((match) => {
          if (
            match.matchResults[0].idPlayer === player1 &&
            match.matchResults[1].idPlayer === player2
          ) {
            return true;
          }
          if (
            match.matchResults[1].idPlayer === player1 &&
            match.matchResults[0].idPlayer === player2
          ) {
            return true;
          }
        });
        return exist;
      };
      const exist = await checkIfMatchExist(player1, player2, matches);
      const alreadyMessage = `Match ${name1} vs ${name2} was previously posted.`;
      if (!exist) {
        await match.createPlayoffsMatch(message);
      } else {
        toast.update(cuToast, {
          render: alreadyMessage,
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 7000,
        });
      }
    }
    toast.dismiss(cuToast);
    onFinish();
  }

  async getPlayersOfChampionshipRound(): Promise<string[]> {
    const matches = await getMatchesByTournamentId(this.tournamentId);
    const matchesByRound: { [key: number]: IMatchResults[] } = matches
      .filter((match) => match.round !== 0)
      .reduce((acc, curr) => {
        if (curr.round) {
          if (acc[curr.round]) {
            acc[curr.round] = [...acc[curr.round], ...curr.matchResults];
          } else {
            acc[curr.round] = [...curr.matchResults];
          }
        }
        return acc;
      }, {} as { [key: number]: IMatchResults[] }); // Add index signature to the type
    const hashMapPlayersWithRoundsData = Object.keys(matchesByRound).reduce(
      (acc, curr) => {
        const playersPerRound = matchesByRound[parseInt(curr)];
        playersPerRound.forEach((player) => {
          console.log(player, "player    '''''''");
          if (acc[player.idPlayer]) {
            acc[player.idPlayer] = [...acc[player.idPlayer], player];
          } else {
            acc[player.idPlayer] = [player];
          }
        });
        return acc;
      },
      {} as { [key: string]: IMatchResults[] }
    ); // Add index signature to the type
    return Object.keys(hashMapPlayersWithRoundsData).map((player) => player);
  }

  async checkIfRoundPlayed(round: number, playerMail: string): Promise<void> {
    if (round !== 0) {
      const matches = await getMatchesByTournamentIdAndRound(
        this.tournamentId,
        round
      );
      this.emailListPlayedRound = matches.flatMap((match) => {
        return match.matchResults.map((player) => player.idPlayer);
      });
    }
    if (round === 0) {
      const players = await this.getPlayersOfChampionshipRound();
      const matches = await getMatchesByTournamentIdAndRound(
        this.tournamentId,
        round
      );
      const played = matches.flatMap((match) => {
        return match.matchResults.map((player) => player.idPlayer);
      });
      this.emailListPlayedRound = players.filter(
        (player) => !played.includes(player)
      );
    }
  }

  async createRound(
    message: string,
    round: number,
    onFinish: () => void
  ): Promise<void> {
    // const matches = await getMatchesByTournamentId(idTournament);
    const displayLoading = getMessages(Messages.LOADING);
    const cuToast = toast.loading(displayLoading);
    for (const match of this.matches) {
      // const player1 = match.players[0].score.idPlayer;
      // const player2 = match.players[1].score.idPlayer;
      // const name1 = match.players[0].score.player;
      // const name2 = match.players[1].score.player;
      // const checkIfMatchExist = async (
      //   player1: string,
      //   player2: string,
      //   matches: IMatch[]
      // ): Promise<boolean> => {
      //   const exist = matches.some((match) => {
      //     if (
      //       match.matchResults[0].idPlayer === player1 &&
      //       match.matchResults[1].idPlayer === player2
      //     ) {
      //       return true;
      //     }
      //     if (
      //       match.matchResults[1].idPlayer === player1 &&
      //       match.matchResults[0].idPlayer === player2
      //     ) {
      //       return true;
      //     }
      //   });
      //   return exist;
      // };
      // const exist = await checkIfMatchExist(player1, player2, matches);
      // const alreadyMessage = `Match ${name1} vs ${name2} was previously posted.`;
      // if (!exist) {
      await match.createDogfightRound(message, round);
      // } else {
      //   toast.update(cuToast, {
      //     render: alreadyMessage,
      //     type: toast.TYPE.ERROR,
      //     isLoading: false,
      //     autoClose: 7000,
      //   });
      // }
    }
    toast.dismiss(cuToast);
    onFinish();
  }
}

export default PlayViewModel;
